<template  lang="pug" >
    div(class="")
        v-row 
            v-col(cols="12")
                v-stepper(v-model="stepper")
                    v-stepper-header(outlined)
                        v-stepper-step(:complete="stepper > 1" step="1")
                        v-divider
                        v-stepper-step(:complete="stepper > 2" step="2")
                        v-divider
                        v-stepper-step(:complete="stepper > 3" step="3")
                        v-divider
                        v-stepper-step(:complete="stepper > 4" step="4")
                    v-stepper-items(outlined)
                        //- paso 1
                        v-stepper-content(step="1")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Push - Corporate Deal
                            ValidationObserver(ref="formStep1")
                                form(@submit.prevent="handleNextStep1")
                                    v-card(class="mb-4" outlined)
                                        v-card-text
                                            v-row 
                                                v-col(cols="6")
                                                    p(class="text-caption  mb-0") *Todos los campos son obligatorios y para tener un control interno de la campaña, independiente del nombre en el layout.
                                            v-row 
                                                v-col(cols="12" sm="4")
                                                        p( class="text-body-2 mb-0") Marca:
                                                        v-row(class="align-baseline")
                                                            v-col(cols="12")
                                                                ValidationProvider(v-slot='{ errors }' name='marca' rules='required')
                                                                    v-autocomplete( 
                                                                        :items="inputMarcaItems" 
                                                                        label="Selecciona una o varias opciones."
                                                                        v-model='form.marca_input'
                                                                        multiple
                                                                        clearable
                                                                        small-chips
                                                                        @change="onChangeMarcas"
                                                                        :disabled="inputMarcaItems.length == 0"
                                                                        :error-messages='errors')
                                                v-col(cols="12" sm="4")
                                                    p( class="text-body-2 mb-0") Categorías:
                                                    v-row(class="align-baseline")
                                                        v-col(cols="12")
                                                            ValidationProvider(v-slot='{ errors }' name='categorias' rules='required')
                                                                v-autocomplete( 
                                                                    :items="inputCategoryItems" 
                                                                    label="Selecciona una o varias opciones."
                                                                    v-model='cats_input_computed'
                                                                    multiple
                                                                    clearable
                                                                    small-chips
                                                                    :disabled="inputCategoryItems.length == 0"
                                                                    :error-messages='errors')
                                            v-row 
                                                v-col(cols="12")
                                                    p( class="text-body-2") Otros filtros
                                                    v-divider(class='mb-3')
                                            v-row
                                                v-col(cols="12" class="pb-0")
                                                    v-checkbox(
                                                        label='Especial fin de semana' 
                                                        v-model='form.flag_especial_fds' 
                                                        dense  
                                                        class="pt-0 mt-0 pb-0 mb-0"
                                                        @change="onChangeFlagEspecialFds")
                                                v-col(cols="12") 
                                                v-col(cols="12" class="pb-0")
                                                    v-checkbox(label='Prueba A/B' v-model='form.flag_ab' dense  class="pt-0 mt-0 pb-0 mb-0")
                                        
                                    v-btn(text x-small @click="$emit('onClickSelectStep', STEPS.GO_TO_PUSH)" class="mr-2") regresar
                                    v-btn( color="primary" type="submit" x-small) continue
                        //- paso 2
                        v-stepper-content(step="2")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Push - Corporate Deal
                            ValidationObserver(ref="formStep2")
                                form(@submit.prevent="handleNextStep2")
                                    v-card(class="mb-4" outlined)
                                        v-card-text
                                            v-row 
                                                v-col(cols="6")
                                                    p( class="text-body-2 mb-0") Escriba el título de la campaña, sin espacios ni caracteres especiales: <br>ej. RegalosParaElla
                                                    v-row(class="align-baseline")
                                                        v-col(cols="10")
                                                            ValidationProvider(v-slot='{ errors }' name='título de la campaña' rules='required')
                                                                v-text-field(label='Título de la campaña' v-model='form.camp_name_int' :error-messages='errors')
                                                        v-col(cols="2")
                                                            v-tooltip( top )
                                                                template( v-slot:activator="{ on }")
                                                                    v-btn( icon v-on="on" x-small class="blue-600")
                                                                        v-icon( color="white") mdi-help
                                                                span El nombre debe ir sin espacios ni caracteres especiales como acentos y tildes
                                            v-row
                                                v-col(cols="6")
                                                    p( class="text-body-2 mb-0") Escriba fecha de lanzamiento <br> Formato DDMMAAAA: ej 24012023
                                                    v-row(class="align-baseline")
                                                        v-col(cols="10")
                                                            v-menu(
                                                            :close-on-content-click="false"
                                                            :nudge-right="40"
                                                            transition="scale-transition"
                                                            offset-y
                                                            min-width="auto")
                                                                template(v-slot:activator='{ on, attrs }')
                                                                    ValidationProvider(v-slot='{ errors }' name='fecha de lanzamiento' rules='required')
                                                                        v-text-field(
                                                                            label='Fecha' 
                                                                            v-model='form.fec_lanzamiento' 
                                                                            :error-messages='errors'
                                                                            prepend-icon="mdi-calendar"
                                                                            v-bind="attrs"
                                                                            v-on="on")
                                                                v-date-picker(
                                                                    v-model="datePickerFechaLanzamiento"
                                                                    @change="handleChangeFechaLanzamiento"
                                                                    @input="menu = false")
                                                        v-col(cols="2")
                                                            v-tooltip( top )
                                                                template( v-slot:activator="{ on }")
                                                                    v-btn( icon v-on="on" x-small class="blue-600")
                                                                        v-icon( color="white") mdi-help

                                    
                                    v-btn(text x-small @click="stepper = 1" class="mr-2") regresar
                                    v-btn( color="primary" type="submit" x-small) continue

                        //- paso 3
                        v-stepper-content(step="3")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Push - Corporate Deal
                            ValidationObserver(ref="formStep3")
                                form(@submit.prevent="handleSubmitForm")
                                    v-card(class="mb-4" outlined)
                                        v-card-text
                                            PushFormModels(:form="form")
                                    v-btn(text x-small @click="stepper = 2" class="mr-2") regresar
                                    v-btn( color="primary" type="submit" x-small :loading="formLoading || jobState == 'running'" :disabled="formLoading || jobState == 'running' || jobState == 'queued'" ) continue
                        //- paso 4
                        v-stepper-content(step="4")
                            h2(class="text-h6  blue-500--text mb-4 font-weight-bold") Push - Corporate Deal
                            v-card(class="mb-4" outlined)
                                v-card-text
                                    v-row 
                                        v-col(cols="4")
                                            v-row 
                                                v-col(cols="12" v-if="jobState == 'running' || jobState == 'failed'")
                                                    v-alert(type="warning" v-if="jobState == 'running' && stepper == 4") Espera a que termine de ejecutarse la petición para obtener tus archivos. 
                                                    v-alert(type="error" v-if="jobState == 'failed' && stepper == 4") Ha ocurrio un erro en el DAG. No pudimos generar tus archivos de descarga.
                                                        v-btn(href="https://a3580af07e05498d982779859d4f535a-dot-us-central1.composer.googleusercontent.com/dags/dag_motor_push/grid?search=dag_motor_push" target="_blank" x-small class="ml-2" color="warning") Ver DAG  <v-icon right size="13">mdi-open-in-new</v-icon>
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small target="_blank" :href="linkVerArchivos" :loading="jobState == 'running' || jobState == 'queued' || jobState == null" :disabled="jobState == 'running' || jobState == 'queued' || jobState == null" ) Ver archivos
                                                v-col(cols="12")
                                                    v-btn(color="success" block x-small target="_blank" href="https://console.cloud.google.com/storage/browser/_details/inputmodels/Motor-de-logicas-para-audiencias/prueba_input_motor_push.json?project=ecommerce-bi" ) Ver JSON
                                                v-col(cols="12")
                                                    v-btn(color="primary"  x-small @click="stepper = 3" ) Regresar
                            v-btn(text x-small @click="handleBtnGenerarNuevaAudiencia" class="mr-2") Generar otra audiencia para push   
                            v-btn( color="primary" @click="handleBtnFinalizar" x-small) Finalizar
</template>
<script>
import { STEPS } from './index.vue';
// Service
import MotorAudienciasService from '../../../services/MotorAudienciasService'
const motorAudienciasService = new MotorAudienciasService();
export default {
    name: 'MedioSelectedPushCategoria',
    components: {
        'PushFormModels': () => import('./PushFormModels')
    },
    data() {
        return {
            STEPS,
            stepper: 1,
            form: {
                camp_tipo: 'Corporate Deal',
                cats_input: [], // Categoría
                marca_input: [], // Marca
                flag_especial_fds: false, 
                flag_ab: false,
                camp_name_int: '',
                fec_lanzamiento: '',
                // búsquedas
                prop_busqueda_cat: '>=0.8',
                prop_busqueda_subcat: '>=0.8',
                prop_busqueda_clase: '>=0.8',
                // compras
                prop_compra_cat: '>=0.8',
                prop_compra_subcat: '>=0.8',
                prop_compra_clase: '>=0.8',
                // vistas
                prop_vista_cat: '>=0.8',
                prop_vista_subcat: '>=0.8',
                prop_vista_clase: '>=0.8',
                // 
                prop_triple: '>=0.8',
                flag_clases: true,
                //
                scoreRecompra: '>=0.8', // Score recompra
                R_lst: [3,4],
                F_lst: [3,4],
                M_lst: [3,4],
                intervalo_dias: 1,
                canales_lst: [],
                flag_temporalidades_extra: false,
                temporalidades_extra: '',
                nivel_mkp: '',
                spc_lst: [],
                scoreCanal: '>=0.8',
                // configuraciones
                folder: '', // nombre del folder dentro del bucket
            },
            formLoading: false,
            datePickerFechaLanzamiento: (new Date()).toISOString().substr(0, 10),
            // taks dag
            jobSubmited: null, // setInterval
            jobState: null, // success - fail, queued, etc. extados de dag de airflow
            jobEndDate: null,
            // inputs data
            categoriasWithSubclasifitations: [],
            inputMarcaItems: [],
            inputCategoryItems: [],
            // inputSubcategoryItems: []
        }
    },
    watch: {
        jobEndDate: function (newJobEndDate, oldJobEndDate) {
            // si jobEndDate es diferente de null, el dag terminó
            if (newJobEndDate) {
                this.stopWatchingJobSubmited()
                this.formLoading = false; // 
            }
            if(newJobEndDate == null) {
                this.formLoading = true;
            }
        }
    },
    computed: {
        cats_input_computed: {
            get() {
                return this.form.cats_input;
            },
            set(newVal) {
                if(newVal.length > 10) {
                    newVal = newVal.slice(newVal.length - 10, newVal.length); // solo se permiten 3 categorias
                }
                this.form.cats_input = newVal;
            }
        },
        linkVerArchivos() {
            if (this.form.folder == '') {
                return "https://console.cloud.google.com/storage/browser/outputmodels/Motor-de-logicas-para-audiencias/PUSH?project=ecommerce-b"
            }
            return `https://console.cloud.google.com/storage/browser/outputmodels/Motor-de-logicas-para-audiencias/PUSH/${this.form.folder};tab=objects?project=ecommerce-bi`
        },
    },
    methods: {
        submitForm() {
            // setear valores antes de eviar
            this.jobEndDate = null;
            this.jobState = null;
            this.formLoading = true;
            // form
            let formData = new FormData();
            Object.entries(this.form).forEach(([key, value]) => {
                if(value != undefined && value.length) {
                    if(Array.isArray(value) ) {
                        formData.append(key, JSON.stringify(value));
                    } else {
                        formData.append(key, value);
                    }
                }
                if (typeof value == 'number' || typeof value == 'boolean' ) {
                    formData.append(key, value);
                }
            });
            // send form
            motorAudienciasService
                .submitFormPush(formData)
                .then(response => {
                    let {state, dag_run_id} = response.data.data
                    this.jobState = state // queued
                    // monitorear estado del dag
                    this.startWatchingJobSubmited(dag_run_id)
                    // recoger el nombre de la carpeta donde se depositaran los archivos
                    if (response.data.data.folder) {
                        this.form.folder = response.data.data.folder;
                    }
                    // guardar estado del formulario
                    this.storeState(response.data.data, this.form, 4)
                    this.stepper = 4;
                })
                .catch(error =>{
                    this.formLoading = false;
                })
                // .finally(() => {
                //     this.formLoading = false;
                // })
        },
        handleNextStep1() {
            this.$refs.formStep1.validate().then(success => {
                if (!success) {
                    return;
                }
                this.stepper = 2;
            });
        },
        handleNextStep2() {
            this.$refs.formStep2.validate().then(success => {
                if (!success) {
                    return;
                }
                this.stepper = 3;
            });
        },
        handleSubmitForm() {
            this.$refs.formStep3.validate().then(success => {
                if (!success) {
                    return;
                }
                this.submitForm();
            });
        },
        handleBtnFinalizar() {
            this.cleanState();
            this.$emit('onClickSelectStep', STEPS.GO_TO_START);

        },
        handleBtnGenerarNuevaAudiencia() {
            this.cleanState();
            this.$emit('onClickSelectStep', STEPS.GO_TO_PUSH)
        },
        handleChangeFechaLanzamiento() {
            let date = this.datePickerFechaLanzamiento.split('-')
            this.form.fec_lanzamiento = `${date[2]}${date[1]}${date[0]}`
        },
        // relacionado a dags
        startWatchingJobSubmited(id) {
            this.jobSubmited = setInterval(this.getJobInfoById, 15000, id)
        },
        stopWatchingJobSubmited() {
            clearInterval(this.jobSubmited)
        },
        onSuccessJobSubmited() {
        },
        getJobInfoById(id) {
            motorAudienciasService
                .getJobInfoById({dag_id: 'dag_motor_push',dag_run_id: id})
                .then(response => {
                    this.jobState = response.data.data.state 
                    this.jobEndDate = response.data.data.end_date
                    if(this.jobState == 'success') { //si el job termina sin problemas
                        this.onSuccessJobSubmited()
                    }
                    if(this.stepper != 4) {
                        this.stepper = 4;
                    }
                })
                .catch(error => {
                    this.stopWatchingJobSubmited()
                })
        },
        storeState({dag_run_id, start_date}, form, step) {
            let data = {
                form_name: 'MOTOR_AUDIENCIAS_PUSH_CORPORATE_DEAL',
                job: {
                    dag_run_id: dag_run_id || null,
                    dag_id: 'dag_motor_push',
                    status: this.jobState || null,
                    start_date: start_date || null,
                    end_date: this.jobEndDate || null,
                },
                form: form,
                step: step // pasar directo al paso 4 si ya envio el formulario
            }
            localStorage.setItem('motorpushcorporatedeal', JSON.stringify(data))
        },
        recoverState() {
            let job = localStorage.getItem('motorpushcorporatedeal')
            return JSON.parse(job)
        },
        cleanState() {
            localStorage.removeItem('motorpushcorporatedeal')
        },
        removeDuplicateItems(arr) {
            return [... new Set(arr)];
        },
        onChangeMarcas() {
            // reset
            this.form.cats_input = [];
            if(this.form.marca_input.length > 0) {
                let _filtered = this.categoriasWithSubclasifitations.filter(item =>{ 
                    return this.form.marca_input.includes(item.marca)
                });
                // this.inputSubcategoryItems = this.removeDuplicateItems(_filtered.map(item => item.subcategoria)).sort();
                this.inputCategoryItems = this.removeDuplicateItems(_filtered.map(item => item.categoria)).sort();
            } else {
                this.inputCategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.categoria) ).sort()
                // this.inputSubcategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.subcategoria) ).sort()
            }
        },
        onChangeFlagEspecialFds() {
            this.form.spc_lst = [];
            this.form.scoreCanal = '>=0.8';
        }
    },
    mounted() {
        // recuperar estado de formulario enviado (por si refrescan la página)
        let state = this.recoverState();
        // si existe un estado de formulario enviado
        if (state && state.form_name == 'MOTOR_AUDIENCIAS_PUSH_CORPORATE_DEAL') {
            // preguntar por el estado del dag enviado en el formulario
            this.getJobInfoById(state.job.dag_run_id)
            // si jobEndDate resulta diferente de null, preguntar por el estado del formulario cada determinado tiempo
            this.startWatchingJobSubmited(state.job.dag_run_id)
            // mandar al paso de descargas si existe un estado de formulario en el local storage
            this.stepper = state.step; // si se pando el formulario desde paso 3, pasar directo a descarga
            // form
            this.form.camp_tipo = state.form.camp_tipo
            this.form.cats_input = state.form.cats_input
            this.form.marca_input = state.form.marca_input
            this.form.flag_especial_fds = state.form.flag_especial_fds
            this.form.flag_ab = state.form.flag_ab
            this.form.camp_name_int = state.form.camp_name_int
            this.form.fec_lanzamiento = state.form.fec_lanzamiento
            this.form.prop_busqueda_cat = state.form.prop_busqueda_cat
            this.form.prop_busqueda_subcat = state.form.prop_busqueda_subcat
            this.form.prop_busqueda_clase = state.form.prop_busqueda_clase
            this.form.prop_compra_cat = state.form.prop_compra_cat
            this.form.prop_compra_subcat = state.form.prop_compra_subcat
            this.form.prop_compra_clase = state.form.prop_compra_clase
            this.form.prop_vista_cat = state.form.prop_vista_cat
            this.form.prop_vista_subcat = state.form.prop_vista_subcat
            this.form.prop_vista_clase = state.form.prop_vista_clase
            this.form.prop_triple = state.form.prop_triple
            this.form.flag_clases = state.form.flag_clases
            this.form.scoreRecompra = state.form.scoreRecompra
            this.form.R_lst = state.form.R_lst
            this.form.F_lst = state.form.F_lst
            this.form.M_lst = state.form.M_lst
            this.form.intervalo_dias = state.form.intervalo_dias
            this.form.canales_lst = state.form.canales_lst
            this.form.flag_temporalidades_extra = state.form.flag_temporalidades_extra
            this.form.temporalidades_extra = state.form.temporalidades_extra
            this.form.nivel_mkp = state.form.nivel_mkp
            this.form.spc_lst = state.form.spc_lst
            this.form.scoreCanal = state.form.scoreCanal
            this.form.folder = state.form.folder
        }
        //
        motorAudienciasService
            .getCategoriesWithSubclasifitacions()
            .then( response => {
                // guardar solo categorias y clase sin repetir objetos
                let categoriasWithSubclasifitations = response.data.data
                this.categoriasWithSubclasifitations = categoriasWithSubclasifitations
                    .map(item => ({marca: item.marca, categoria: item.categoria}))
                    .filter((item, index, self) =>
                        index === self.findIndex((t) => (
                            t.marca === item.marca && t.categoria === item.categoria
                        ))
                    );
				this.inputMarcaItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.marca) ).sort()
                this.inputCategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.categoria) ).sort()
                // this.inputSubcategoryItems = this.removeDuplicateItems(  this.categoriasWithSubclasifitations.map(item => item.subcategoria) ).sort()
            });
    },
    beforeDestroy() {
        this.stopWatchingJobSubmited();
    },
}
</script>

<style scoped>
.embed-container {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
}
.embed-container iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
}
</style>